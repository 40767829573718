import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import { ImCancelCircle } from 'react-icons/im';
import {
  MainLayout,
  ContentArea,
  PostArea,
  InnerBody,
  Form,
  PostButton,
  PostText,
  BottomContainer,
  SelectPic,
  UploadedPics,
  OnePic,
  LimitText,
  CancelBtn,
  UploadButton,
  Tags,
  OneTag,
  TagName,
  CheckBox,
} from './index.styles';
import Image from 'next/image';

import { useDropzone } from 'react-dropzone';
import axios from '../../utils/api';
import { uploadAllToCloudinary } from '@components/reusable/helpers';
import { CenteredSpinner } from '@components/reusable/index.spinner';
import messageBanner from '@components/reusable/messageBanner';
import { tags } from '../../mock/tags';
import { useRouter } from 'next/router';

export default function CreatePost({ close, reRender }) {
  const [loading, setLoading] = useState(false);
  const [postText, setPostText] = useState('');
  const [postTags, setPostTags] = useState([]);
  const unCheckAll = () => {
    let tags = postTags;
    tags.map((tag) => {
      tag.checked = false;
    });
    setPostTags(tags);
  };
  useEffect(() => {
    if (tags) {
      setPostTags(tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const router = useRouter();

  const [selectedCount, setSelectedCount] = useState(0);
  const handlerText = (e) => {
    setPostText(e.target.value);
    setWords(e.target.value.length);
  };
  const [words, setWords] = useState(0);
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/jpg',
    onDrop: (acceptedFiles) => {
      const renamedAcceptedFiles = acceptedFiles.slice(0, 5).map(
        (file) =>
          new File([file], `${file.name}_${+new Date()}`, {
            type: file.type,
          }),
      );
      const newFile = renamedAcceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );
      setFiles([...files, ...newFile]);
    },
  });

  const filter = (id) => files.filter((file) => file.name !== id);
  const remove = (id, e) => {
    let newFiles = filter(id);
    setFiles(newFiles);
    e.stopPropagation();
  };

  const [sendData, setSendData] = useState(false);

  const submitPost = (e) => {
    e.preventDefault();
    setSendData(true);
  };

  const [text, setText] = useState('Creating your post');

  useEffect(() => {
    const sendPost = async (obj) => {
      try {
        // eslint-disable-next-line no-unused-vars
        const data = await axios.post('/posts/create', obj);
        setFiles([]);
        setSelectedCount(0);
        unCheckAll();
        close();
        setLoading(false);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        messageBanner({
          message: `Post created successfully 😊`,
          status: 'success',
        });
        reRender(true);
        localStorage.setItem('flush', 'true');
        router.push(router.asPath + '?flush=true');
      } catch (error) {
        setLoading(false);
        close();
        setFiles([]);
        messageBanner({
          message: `Something went wrong, try again 😓`,
          status: 'error',
        });
      }
    };

    if (sendData) {
      setLoading(true);
      const postObj = {
        textContent: postText,
        userId: localStorage.getItem('userid'),
        tags: postTags.filter((tag) => tag.checked).map((tag) => tag.name),
      };

      if (files.length > 0) {
        setText('Uploading images...');
        // sendPost(postObj);
        uploadAllToCloudinary(files).then(function (results) {
          const postObj = {
            textContent: postText,
            images: results,
            tags: postTags.filter((tag) => tag.checked).map((tag) => tag.name),
            userId: localStorage.getItem('userid'),
          };
          setText('Wrapping up...');
          // post to server
          sendPost(postObj);
        });
      } else {
        sendPost(postObj);
      }
    }
  }, [sendData]);

  const [changed, setChanged] = useState(false);
  const beforeClose = (e) => {
    // timeout of 5s
    if (e.target === e.currentTarget) {
      setChanged(true);
      setTimeout(() => {
        close();
      }, 500);
    }
  };

  const checkSpan = (index) => {
    let checkedList = [...postTags];
    checkedList[index].checked = !checkedList[index].checked;
    setPostTags(checkedList);
    // count the checked items in the list and set the state
    setSelectedCount(selectedCount + (checkedList[index].checked ? 1 : -1));
  };

  return (
    <MainLayout onClick={(e) => beforeClose(e)}>
      {loading ? (
        <CenteredSpinner text={text} />
      ) : (
        <ContentArea key={changed} beenChanged={changed}>
          <div
            className="cancel"
            onClick={() => {
              setChanged(true);
              unCheckAll();
              setTimeout(() => {
                close();
              }, 500);
            }}
          >
            <Image
              src={'/assets/close.svg'}
              alt="x"
              height={20}
              width={20}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </div>
          <PostArea>
            <InnerBody>
              <Form>
                <div className="relative">
                  <PostText
                    type="textarea"
                    name="posttext"
                    value={postText}
                    onChange={(e) => handlerText(e)}
                    required
                    maxLength={200}
                    placeholder="What's on your mind?"
                  />
                  <LimitText>{words + '/200'}</LimitText>
                </div>
                <p className="tag-headline">
                  Pick tags for your post <span>{`(${selectedCount}/3)`}</span>
                </p>
                <Tags>
                  {postTags.map((tag, index) => (
                    <OneTag key={index}>
                      <CheckBox
                        type="checkbox"
                        name={tag.name}
                        value={tag.name}
                        checked={tag.checked}
                        onChange={() => checkSpan(index)}
                        disabled={selectedCount === 3 && !tag.checked}
                        required
                      />
                      <TagName>{tag.name}</TagName>
                    </OneTag>
                  ))}
                </Tags>
                <BottomContainer>
                  <SelectPic {...getRootProps()}>
                    <input {...getInputProps()} />
                    {files.length < 3 && (
                      <UploadButton>
                        <p>Upload images</p>
                        <MdOutlineAddPhotoAlternate
                          className="addimage"
                          fontSize="2em"
                          color="#fff"
                        />
                      </UploadButton>
                    )}
                    <UploadedPics>
                      {files.map((file, ind) => (
                        <div className="rel" key={ind}>
                          <OnePic src={file.preview} />
                          <CancelBtn
                            onClick={(e) => remove(file.name, e)}
                            className="removeImage"
                          >
                            <ImCancelCircle className="icon" fontSize="1.5em" />
                          </CancelBtn>
                        </div>
                      ))}
                    </UploadedPics>
                  </SelectPic>
                  <PostButton
                    type="submit"
                    disabled={selectedCount < 1 || postText.length < 3}
                    onClick={(e) => submitPost(e)}
                  >
                    Create Post
                  </PostButton>
                </BottomContainer>
              </Form>
            </InnerBody>
          </PostArea>
        </ContentArea>
      )}
    </MainLayout>
  );
}

CreatePost.propTypes = {
  submitPost: PropTypes.func,
  close: PropTypes.func,
  reRender: PropTypes.func,
};
