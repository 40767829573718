import styled, { keyframes, css } from 'styled-components';

export const ShowUpModal = keyframes`
// slide up
0% {
  transform: translateY(10vh);
  opacity: 0.5;
}
100% {
  transform: translateY(0);
  opacity: 1;
}`;
export const CloseModal = keyframes`
// slide up
0% {
  transform: translateY(0);
  opacity: 1;
}
100% {
  transform: translateY(-10vh);
  opacity: 0.01;
}`;
export const MainLayout = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(1px);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShowImage = keyframes`
// slide in from left
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }`;
export const ContentArea = styled.div`
  padding: 1rem;
  border-radius: 15px;
  background-color: #15202b;
  position: relative;

  max-width: 600px;

  ${(props) =>
    props?.beenChanged
      ? css`
          animation: ${CloseModal} 0.5s linear;
        `
      : css`
          animation: ${ShowUpModal} 0.5s linear;
        `}
  .cancel {
    position: absolute;
    cursor: pointer;
    right: 0.5rem;
    top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all 0.3s;
    &:hover {
      background-color: #194547;
      border-radius: 50%;
    }
  }
  .icon {
    color: #f1f1f1;
    cursor: pointer;
    background-color: #194547;
    border-radius: 50%;
  }
  .rel {
    position: relative;
    height: 75px;
    width: 75px;
    margin-right: 0.5rem;
    animation: ${ShowImage} 0.5s ease-in-out;
    @media screen and (max-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  .removeImage {

    transition: all 0.3s ease;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: auto;
    height: 100%;
    border-radius: 0;
    padding: 2rem;
  }
`;
export const PostArea = styled.div`
  .tag-headline {
    padding: 0;
    color: white;
    font-size: 1rem;
    margin-bottom: 1rem;
    span {
      transition: all 0.3s ease-in;
      color: #4fd8e0;
    }
  }
`;
export const InnerBody = styled.div`
  // padding-left: 4rem;
  @media screen and (max-width: 768px) {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;
export const Form = styled.form`
  .relative {
    // position: relative;
    margin-bottom: 1rem;
  }
`;
export const PostText = styled.textarea`
  height: 90px;
  width: 100%;
  background-color: #000;
  color: #fff;
  outline: 0;
  border: 0;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  font-size: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 200px;
    margin-top: 1.5rem;
  }
`;
export const LimitText = styled.p`
  position: absolute;
  right: 0.3rem;
  bottom: -1.3rem;
  margin: 0;
  padding: 0;
  color: #4fd8e0;
  opacity: 0.5;
  font-size: 12px;
`;
export const CancelBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
export const BottomContainer = styled.div`
  margin-top: 3rem;
  p {
    margin: 0;
    color: #f1f1f1;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    @media screen and (max-width: 768px) {
      width: 95%;
    }
  }
`;
export const SelectPic = styled.div``;
export const UploadPic = styled.input``;
export const PostButton = styled.button`
  background-color: #15202b;
  color: #f1f1f1;
  position: absolute;
  bottom: 1.5rem;
  right: 4rem;
  height: 40px;
  width: 200px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #4fd8e0;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #f1f1f1;
  }
  @media screen and (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    right: unset;
    bottom: 2rem;
    width: 150px;
    margin: auto;
  }
  &:disabled {
    border: 1px solid #404040;
    color: #404040;
    cursor: default;
  }
`;
export const UploadedPics = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  .addimage {
    color: #4fd8e0;
    margin-right: 0.5rem;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    align-center: center;
    justify-content: center;
  }
`;

export const UploadButton = styled.button`
  padding: 0 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
  background: inherit;
  border-radius: 5px;
  margin-top: 1rem;
  border: 1px solid white;
  transition: all 0.3s ease-in;
  &:hover {
    cursor: pointer;
  }
  p {
    margin-left: 0.5rem;
  }
  &:hover {
    transform: none;
    border: 1px solid #4fd8e0;
  }
`;

export const OnePic = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  z-index: 4;
`;

export const Tags = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const Tag = styled.span`
  font-size: 10px;
  padding: 0.1rem 0.5rem;
  border-radius: 25px;
  border: 1px solid white;
  transition: all 0.3s ease-in;
  color: white;
  background: ${(props) => props.backgroundColor};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    cursor: pointer;
  }
`;

export const OneTag = styled.label`
  font-weight: 500;
  font-size: 22px;
`;

export const TagName = styled.span`
  font-size: 15px;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid white;
  color: white;
  background: ${(props) => props.backgroundColor};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  &:hover {
    cursor: pointer;
  }
`;

export const CheckBox = styled.input`
  border: none;
  outline: none;
  display: none;
  &:checked + ${TagName} {
    background-color: white;
    color: black;
    outline: 0;
    border: 1px solid white;
  }
`;
