import styled, { css } from 'styled-components';

export const NotificationWrapper = styled.aside`
  width: 19vw;
  margin-left: 1rem;
  overflow-y: auto;
  position: fixed;
  padding: 1rem;
  border-radius: 5px;
  right: 1rem;
  background: rgba(42, 163, 239, 0.05);
  .delete {
    transition: all 0.3s linear;
    cursor: pointer;
    color: #f1f1f1;
    &:hover {
      color: red !important;
    }
  }
  .icon {
    color: #f1f1f1;
  }
  .refresh {
    color: #f1f1f1;
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
      color: #4fd8e0 !important;
    }
  }
  h1 {
    color: #f1f1f1;
    // font-size: 1.5vw;
    text-align: center;
    font-weight: 500;
  }
  /* overflow-y: scroll; */
  max-height: 70vh;

  .both {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    @media screen and (max-width: 900px) {
      width: 90%;
    }
    .delete {
      position: absolute;
      /* bottom: -10px; */
      transform: translateY(-50%);
      right: 10px;
      z-index: 10;
      /* outline: thin solid red; */
    }
    .icon {
      transition: all 0.3s linear;
      cursor: pointer;
      color: #f1f1f1;
      &:hover {
        color: #4fd8e0;
      }
    }
  }

  .notification {
    width: 85%;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    padding-left: 0.5rem;
    height: 50px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 1.2rem;
    /* outline: thin solid white; */
    z-index: 5;
    border-radius: 10px;
    img {
      border-radius: 50%;
    }
    &:hover {
      background-color: #283e52;
      cursor: pointer;
    }

    .icon {
      transition: all 0.3s linear;
      cursor: pointer;
      color: #f1f1f1;
      &:hover {
        color: #4fd8e0;
      }
    }
    @media screen and (max-width: 900px) {
      margin: 0;
      width: 82%;
      margin-bottom: 1rem;
    }
  }
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  @media screen and (max-width: 900px) {
    position: fixed;
    top: 80px;
    right: 0;
    left: 0;
    bottom: 65px;
    height: auto;
    max-height: unset;
    width: 92vw;
    width: 100vw;

    background: rgb(25, 69, 71);
    z-index: 500;
    margin: 0;
    border-radius: 0;
    h1 {
      color: #f1f1f1;
      font-size: 4vw;
      text-align: center;
    }
    /* margin-bottom: 2rem; */
  }
  ${(props) =>
    props?.fromweb &&
    css`
      @media screen and (max-width: 900px) {
        display: none;
      }
    `}
`;
export const OneNotification = styled.div`
  padding: 0.5rem 0;
  margin: 0.5rem 0;
`;
export const TheText = styled.p`
  color: #f1f1f1;
  padding-left: 0.5rem;
  font-size: 11px;
  font-weight: 500;
`;
export const TheAction = styled.p`
  padding-left: 0.5rem;
  color: #f1f1f1;
  text-align: left;
  width: 65%;
  font-size: 12px;
  font-weight: 500;
`;
export const TheTime = styled.p`
  padding-left: 0.5rem;
  color: #f1f1f1;
  font-size: 11px;
  position: absolute;
  right: 5%;
  font-weight: 500;
  background-color: #15202b;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
`;

export const Seen = styled.div`
  position: absolute;
  right: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: ${(props) => (props.seen ? 'none' : '#4fd8e0')};
`;

export const Pic = styled.div`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  height: 30px;
  width: 30px;
  border-radius: 50%;
`;
