import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Links,
  LinkText,
  Button,
  ClickAbleArea,
  Linker,
} from './index.styles';
import { useRouter } from 'next/router';

import { FaUserCircle } from 'react-icons/fa';
import { BsBookmarkFill } from 'react-icons/bs';
import {
  MdExplore,
  MdLogout,
  MdOutlineSettings,
  MdCreate,
} from 'react-icons/md';
import { signOut, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useSelector } from 'react-redux';

export default function LeftNav({ showCreate, showingNotif, showPreview }) {
  const router = useRouter();
  const { data: session } = useSession();

  const userinfo = useSelector((state) => state.auth.userInfo);

  const switchPage = (val) => {
    if (!session) {
      return '/';
    }
    switch (val) {
      case 0:
        return '/';
      case 2:
        return '/bookmarks';
      case 3:
        return `/explore/social`;

      case 4:
        return `/profile/${userinfo?.handle}`;
      case 5:
        return `/settings`;
      default:
        return '/';
    }
  };

  return (
    <Wrapper role="navigation">
      <Links className="mb-6">
        <Link
          href={switchPage(0)}
          className={`${router.pathname === '/' ? 'active' : ''} linker`}
        >
          <ClickAbleArea className="-ml-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill={router.pathname === '/' ? '#4fd8e0' : '#f1f1f1'}
              className="ml-[0.15rem] h-8 w-8"
            >
              <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
              <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
            </svg>

            <LinkText className="mv" isActive={router.pathname === '/'}>
              Home
            </LinkText>
          </ClickAbleArea>
        </Link>
        <Link
          href={switchPage(3)}
          className={`${
            router.pathname.includes('explore') ? 'active' : ''
          } linker`}
        >
          <ClickAbleArea className="-ml-1">
            {router.pathname.includes('explore') ? (
              <MdExplore
                className="icon noti"
                color="#4fd8e0"
                fontSize="2.1rem"
              />
            ) : (
              <MdExplore
                className="icon noti"
                color="#f1f1f1"
                fontSize="2.1rem"
              />
            )}
            <LinkText
              className="mv"
              isActive={router.pathname.includes('explore')}
            >
              Explore
            </LinkText>
          </ClickAbleArea>
        </Link>

        <Link
          href={switchPage(2)}
          className={`${
            router.pathname === '/bookmarks' ? 'active' : ''
          } linker`}
        >
          <ClickAbleArea className="-ml-1">
            {router.pathname === '/bookmarks' ? (
              <BsBookmarkFill
                className="icon bk"
                color="#4fd8e0"
                fontSize="2.5rem"
              />
            ) : (
              <BsBookmarkFill
                className="icon bk"
                color="#f1f1f1"
                fontSize="2.5rem"
              />
            )}
            <LinkText
              className="mv"
              isActive={router.pathname === '/bookmarks'}
            >
              Bookmarks
            </LinkText>
          </ClickAbleArea>
        </Link>

        <Link
          href={switchPage(4)}
          className={`${
            router.pathname.includes('/profile') ? 'active' : ''
          } linker`}
        >
          <ClickAbleArea>
            {router.pathname.includes('/profile') && !showingNotif ? (
              <FaUserCircle
                className="icon"
                color="#4fd8e0"
                fontSize="1.8rem"
              />
            ) : (
              <FaUserCircle
                className="icon"
                color="#f1f1f1"
                fontSize="1.8rem"
              />
            )}
            <LinkText
              isActive={router.pathname.includes('/profile') && !showingNotif}
            >
              Profile
            </LinkText>
          </ClickAbleArea>
        </Link>
        <Link
          href={switchPage(5)}
          className={`${
            router.pathname.includes('/settings') ? 'active' : ''
          } linker`}
        >
          <ClickAbleArea>
            {router.pathname === '/settings' && !showingNotif ? (
              <MdOutlineSettings
                className="icon pr"
                color="#4fd8e0"
                fontSize="2rem"
              />
            ) : (
              <MdOutlineSettings
                className="icon pr"
                color="#f1f1f1"
                fontSize="2rem"
              />
            )}
            <LinkText
              isActive={router.pathname === '/settings' && !showingNotif}
            >
              Settings
            </LinkText>
          </ClickAbleArea>
        </Link>
        {userinfo?.handle && (
          <Linker
            className={`linker`}
            onClick={() => {
              signOut({ callbackUrl: '/' });
              localStorage.clear();
            }}
          >
            <ClickAbleArea>
              <MdLogout className="icon pr" color="#f1f1f1" fontSize="1.8rem" />

              <LinkText isActive={false}>Logout</LinkText>
            </ClickAbleArea>
          </Linker>
        )}
      </Links>
      <Button
        className="web"
        onClick={() => {
          !userinfo?.handle ? showPreview() : showCreate();
        }}
      >
        New Post
      </Button>
      <Button
        onClick={() => {
          !userinfo?.handle ? showPreview() : showCreate();
        }}
        className="mobile flex items-center justify-center"
      >
        <MdCreate className="icon create" />
      </Button>
    </Wrapper>
  );
}

LeftNav.propTypes = {
  showCreate: PropTypes.func.isRequired,
  switchPage: PropTypes.func,
  currentPage: PropTypes.number,
  goToProfile: PropTypes.func,
  showingNotif: PropTypes.bool,
  toggleNotif: PropTypes.func,
  showPreview: PropTypes.func,
};
