import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoMdRefresh } from 'react-icons/io';
import PropTypes from 'prop-types';
import {
  NotificationWrapper,
  TheAction,
  TheTime,
  Seen,
  Pic,
} from './index.styles';
import axios from '../../utils/api';

import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

export default function NotificationsUser({
  notifications,
  fromweb,
  search,
  getNewNotifications,
}) {
  const router = useRouter();
  // useeffect check time

  const [allNotifs, setAllNotifs] = useState(null);

  const { data: session, status } = useSession();

  useEffect(() => {
    setAllNotifs(notifications);
    setActiveNot(null);
  }, [notifications]);

  const processTime = (time) => {
    const todayTime = new Date().getTime();
    const timeTime = new Date(time).getTime();
    // difference in seconds
    const diff = (todayTime - timeTime) / 1000;
    // difference in minutes
    const diffMinutes = Math.floor(diff / 60);
    // difference in hours
    const diffHours = Math.floor(diff / 3600);
    // difference in days
    const diffDays = Math.floor(diff / 86400);
    // console log all differences
    if (diffDays > 0) {
      return `${diffDays}d`;
    }
    if (diffHours > 0) {
      return `${diffHours}h`;
    }
    if (diffMinutes > 0) {
      return `${diffMinutes}m`;
    }
    return `<1m`;
  };

  const readNotification = async (id, postID, type, handle) => {
    type === 'follow'
      ? router.push(`/profile/${handle}`)
      : router.push(`/post/${postID}`);
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await axios.put(`/notifications/${id}/seen`, { seen: true });
    } catch (error) {
      console.log(error);
    }
  };
  const removeNot = async (id) => {
    const filtered = allNotifs.filter((file) => file._id !== id);
    setAllNotifs(filtered);
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await axios.delete(`/notifications/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const [activeNot, setActiveNot] = useState(null);
  return (
    <NotificationWrapper fromweb={fromweb} search={search}>
      <IoMdRefresh
        className="icon refresh"
        onClick={() => getNewNotifications()}
      />
      {!allNotifs ? (
        <h1>Loading ... ⌛ </h1>
      ) : allNotifs.length === 0 ? (
        <h1 className="text-md">
          {session && status !== 'loading'
            ? 'No notifications yet'
            : 'Login to like, comment and follow'}{' '}
          😎
        </h1>
      ) : (
        allNotifs?.map((item, index) => (
          <div className="both" key={index}>
            <div
              className="notification"
              onClick={() =>
                readNotification(
                  item?._id,
                  item?.postId,
                  item?.type,
                  item?.user?.handle,
                )
              }
            >
              <Pic
                className="pic"
                src={
                  item?.user?.avatar?.url
                    ? item?.user?.avatar?.url
                    : item?.user?.avatar?.secure_url
                    ? item?.user?.avatar?.secure_url
                    : `https://avatars.navu360.com/api/micah/${item?.user?._id}.svg?mouth[]=laughing&mouth[]=smile&hair[]=mrClean&hair[]=fonze&earringsProbability=0&facialHairProbability=0&backgroundType=gradientLinear,solid&backgroundColor=b6e3f4,c0aede,d1d4f9`
                }
              />
              <TheAction>
                {'@' +
                  item?.user?.handle +
                  (item.type === 'like'
                    ? ' liked your post '
                    : item.type === 'follow'
                    ? ' followed you '
                    : ' commented on your post ')}
              </TheAction>
              <TheTime className="time">{processTime(item.createdAt)}</TheTime>
              <Seen seen={item.seen || activeNot === index} />
            </div>
            <AiOutlineDelete
              onClick={() => removeNot(item?._id)}
              className="icon delete"
              fontSize="1.5em"
            />
          </div>
        ))
      )}
    </NotificationWrapper>
  );
}

NotificationsUser.propTypes = {
  notifications: PropTypes.array,
  fromweb: PropTypes.bool,
  search: PropTypes.bool,
  getNewNotifications: PropTypes.func,
};
