import GeneralLayout from '@components/global/Layout';
import Navbar from '@components/global/Navbar';
import LeftNav from '@components/leftNav';
import NotificationsUser from '@components/notifications';
import { MainSection, CurrentContent } from '@styles/layouts/layout.styles';
import React, { useState, useEffect, useRef } from 'react';
import CreatePost from '@components/createPost/index';

import PropTypes from 'prop-types';

import { useRouter } from 'next/router';
import axios from '../../utils/api';
import messageBanner from '@components/reusable/messageBanner';
import PreviewModal from '@components/reusable/previewModal';
import { useSelector } from 'react-redux';

export default function Layout({ children, renderFromCreate }) {
  const audioRef = useRef(null);
  const router = useRouter();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleModalCreate = () => {
    setShowCreateModal(!showCreateModal);
  };

  const userinfo = useSelector((state) => state.auth.userInfo);

  // id in localstorage
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    localStorage.getItem('userid') && setUserId(localStorage.getItem('userid'));
  }, []);

  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    rerender && renderFromCreate(true);
  }, [rerender]);

  // if localstorage new is one show message
  useEffect(() => {
    if (localStorage.getItem('new') === 'one') {
      messageBanner({
        message: `Handle created, welcome 😍`,
        status: 'success',
      });

      localStorage.setItem('new', 'zero');
    }
    // if localstorage first is one show message
    if (localStorage.getItem('first') === 'one' && router.pathname === '/') {
      messageBanner({
        message: `Welcome to your feed! `,
        status: 'success',
      });
      localStorage.setItem('first', 'zero');
    }
  }, []);

  const [notifications, setNotifications] = useState(null);

  // background checker for notifications
  // const [prev, setPrev] = useState(0);
  const [hasUnread, setHasUnread] = useState(false);
  const [newNotif, setNewNotif] = useState(false);

  useEffect(() => {
    setNotifications(null);
    const getNotifications = async () => {
      try {
        const data = await axios.get(`/notifications/user/${userId}`);

        // don't show own actions
        const filtered = data.data.filter((obj) => obj.authorId === userId);
        // bug -
        const removedOwn = filtered.filter((obj) => userId !== obj.user._id);

        setNotifications(removedOwn);

        setHasUnread(
          removedOwn.filter((obj) => !obj.seen).length > 0
            ? removedOwn.filter((obj) => !obj.seen).length
            : null,
        );

        // setAudio(new Audio('ding.mp3'));
        if (removedOwn.length > 0) {
          if (parseInt(localStorage.getItem('bell')) !== removedOwn.length) {
            !removedOwn[0].seen &&
              audioRef.current.play() &&
              messageBanner({
                message: `You have a new notification 😇`,
                status: 'info',
                position: 'top-right',
              });
          }
        }

        localStorage.setItem('bell', removedOwn.length);

        // ChirpChirp.play();
      } catch (error) {
        console.log(error);
        setNotifications([]);
      }
    };
    // execute function every 5 seconds
    userId ? getNotifications() : setNotifications([]);
  }, [userId, newNotif]);

  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    router.pathname.includes('/post') && setIsInView(true);
  }, []);

  const [showNotifs, setShowNotifs] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  return (
    <GeneralLayout>
      <>
        <Navbar
          count={hasUnread}
          showingNotif={showNotifs}
          show={!!userinfo?.handle}
          toggleNotif={(val) => setShowNotifs(!val ? false : !showNotifs)}
        />
        <MainSection
          isInView={isInView}
          fromview={router.pathname.includes('/post')}
        >
          <LeftNav
            goToProfile={() => router.push(`/profile/${userinfo?.handle}`)}
            showCreate={() => toggleModalCreate()}
            showingNotif={showNotifs}
            toggleNotif={(val) => setShowNotifs(!val ? false : !showNotifs)}
            count={hasUnread}
            showPreview={() => setShowPreview(true)}
          />
          <CurrentContent className="hold-content">{children}</CurrentContent>

          <NotificationsUser
            notifications={notifications}
            // userinfo={userData}
            fromweb
            getNewNotifications={() => setNewNotif(!newNotif)}
          />
        </MainSection>
      </>

      {showCreateModal && (
        <CreatePost
          showSuccess={() => null}
          reRender={(val) => setRerender(val)}
          close={() => toggleModalCreate()}
        />
      )}
      {showPreview && <PreviewModal close={() => setShowPreview(false)} />}
      {showNotifs && <NotificationsUser notifications={notifications} />}
      <audio id="a1" ref={audioRef} src="/ding.mp3"></audio>
    </GeneralLayout>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  renderFromCreate: PropTypes.func,
  sendToPage: PropTypes.func,
  fetchedBookMarks: PropTypes.func,
  fetchedBookMarkPosts: PropTypes.func,
};
