import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 16rem;
  padding: 2rem;
  position: fixed;
  left: 3rem;
  border-radius: 5px;
  background: rgba(42, 163, 239, 0.05);

  .web {
    display: block;
  }
  .mobile {
    display: none;
  }

  @media screen and (max-width: 900px) {
    /* display: none; */
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    height: 40px;
    bottom: 0;
    /* outline: thin solid red; */

    background: rgb(25, 69, 71);
    background: radial-gradient(
      circle,
      rgba(25, 69, 71, 1) 7%,
      rgba(21, 32, 43, 1) 74%
    );
    z-index: 10;
    .mobile {
      display: block;
    }
    .web {
      display: none;
    }
    .create {
      margin: auto;
    }
  }
`;
export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-top: -1rem;
  }
  .linker {
    padding: 0;
    display: flex;
    align-items: center;

    // gap: 20px;

    p {
      color: ${(props) => (props.disabled ? '#404040' : '#f1f1f1')};
    }
    &:hover {
      cursor: ${(props) => (props.disabled ? 'default !important' : 'pointer')};
    }
  }
`;
export const Linker = styled.div`
  padding: 0;
  display: flex;
  align-items: center;

  // gap: 20px;

  p {
    color: ${(props) => (props.disabled ? '#404040' : '#f1f1f1')};
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? 'default !important' : 'pointer')};
  }
`;
export const ClickAbleArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;

  .icon {
    padding: 0px;
  }
  .noti {
    padding: 0;
    margin-right: 6px;
  }
  .bk {
    // margin-right: 0px;
    padding: 0;
    margin-left: 2px;
    font-size: 25px;
  }
  .pr {
    margin-left: -2px;
  }

  nav {
    position: absolute;
    left: 30%;
  }
`;
export const LinkText = styled.nav`
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: ${(props) => (props.isActive ? '#4fd8e0' : '#f1f1f1')};
  &:hover {
    color: ${(props) => (props.disabled ? '#404040' : '#4fd8e0')};
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
export const Button = styled.button`
  border-radius: 30px;
  background: #f1f1f1;
  /* width: 186px; */
  width: 90%;
  height: 46px;
  color: #000;
  font-weight: 500;
  letter-spacing: -0.02em;
  font-size: 21px;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    transform: scale(1.03);
  }
  @media screen and (max-width: 900px) {
    position: absolute;
    bottom: 85px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0rem;
    font-size: 25px;
  }
`;
